import React from "react";

export type Props = {
  altText: string;
  mainOrRelative: "main" | "relative";
  strainSymbol: string;
  topTerpName: string | null | undefined;
};

const StrainLineageNuclei = ({
  altText,
  mainOrRelative,
  strainSymbol,
  topTerpName,
}: Props): JSX.Element => {
  const iconSize = mainOrRelative === "main" ? 80 : 55;
  const textSize = mainOrRelative === "main" ? "base" : "xs";

  const topTerpColor = topTerpName
    ? `text-${topTerpName}`
    : "text-deep-green-40";

  return (
    <div className="relative text-center">
      <svg
        data-testid="nuclei-svg"
        className={topTerpColor}
        xmlns="http://www.w3.org/2000/svg"
        width={iconSize}
        height={iconSize}
        viewBox="-75 -75 150 150"
      >
        <title>{altText}</title>
        <path transform="scale(1,1)" d="M0-62L62 0 0 62-62 0z" />
      </svg>
      <div
        className={`absolute font-bold text-white top-2/4 left-2/4 text-${textSize}`}
        style={{ transform: "translate(-50%, -50%)" }}
      >
        {strainSymbol}
      </div>
    </div>
  );
};

export default StrainLineageNuclei;
