import { RefObject, useCallback, useEffect, useState } from "react";

type Rects = { [key: string]: DOMRect };

/**
 * Given an object of React element refs, an array of rect objects will be
 * returned that will be updated whenever the window is resized.
 */
export default function useResizingRects(
  refs: Record<string, RefObject<HTMLDivElement>>,
) {
  const [rects, setRects] = useState<Rects>({});

  const getNewRects = useCallback(() => {
    const newRects: Rects = {};

    for (const ref of Object.keys(refs)) {
      const { current } = refs[ref];

      if (current) {
        newRects[`${ref.substring(0, ref.length - 1)}ct`] =
          current.getBoundingClientRect();
      }
    }

    setRects(newRects);
  }, [refs]);

  useEffect(() => {
    getNewRects();
    window.addEventListener("resize", getNewRects);

    return () => {
      window.removeEventListener("resize", getNewRects);
    };
  }, [refs, getNewRects]);

  return rects;
}
